import { useEffect, useState } from "react";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { MdOutlineCheckCircle } from "react-icons/md";

import useLocalStorage from "./hooks/useLocalStorage";
function App() {
  const [leseplan, setLeseplan] = useLocalStorage("leseplan", [
    {
      completed: false,
      day: 0,
      verses: [
        {
          completed: false,
          text: "1 Mos 1:1-2:17",
        },
        {
          completed: false,
          text: " Matt1:1-25",
        },
        {
          completed: false,
          text: " Ps 1:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 1,
      verses: [
        {
          completed: false,
          text: "1 Mos 2:18-4:16",
        },
        {
          completed: false,
          text: " Matt 2:1-18",
        },
        {
          completed: false,
          text: " Ps 2:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 2,
      verses: [
        {
          completed: false,
          text: "1 Mos 4:17-6:22",
        },
        {
          completed: false,
          text: " Matt 2:19-3:17",
        },
        {
          completed: false,
          text: " Ps 3:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 3,
      verses: [
        {
          completed: false,
          text: "1 Mos 7:1-9:17",
        },
        {
          completed: false,
          text: " Matt 4:1-22",
        },
        {
          completed: false,
          text: " Ords 1:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 4,
      verses: [
        {
          completed: false,
          text: "1 Mos 9:18-11:9",
        },
        {
          completed: false,
          text: " Matt 4:23-5:20",
        },
        {
          completed: false,
          text: " Ps 4:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 5,
      verses: [
        {
          completed: false,
          text: "1 Mos 11:10-13:18",
        },
        {
          completed: false,
          text: " Matt 5:21-42",
        },
        {
          completed: false,
          text: " Ps 5:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 6,
      verses: [
        {
          completed: false,
          text: "1 Mos 14:1-16:16",
        },
        {
          completed: false,
          text: " Matt 5:43-6:24",
        },
        {
          completed: false,
          text: " Ps 6:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 7,
      verses: [
        {
          completed: false,
          text: "1 Mos 17:1-18:33 ",
        },
        {
          completed: false,
          text: " Matt 6:25-7:23 ",
        },
        {
          completed: false,
          text: " Ords 1:8-19",
        },
      ],
    },
    {
      completed: false,
      day: 8,
      verses: [
        {
          completed: false,
          text: "1 Mos 19:1-20:18",
        },
        {
          completed: false,
          text: " Matt 7:24-8:22",
        },
        {
          completed: false,
          text: " Ps 7:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 9,
      verses: [
        {
          completed: false,
          text: "1 Mos 21:1-23:20",
        },
        {
          completed: false,
          text: " Matt 8:23-9:13",
        },
        {
          completed: false,
          text: " Ps 7:10-17",
        },
      ],
    },
    {
      completed: false,
      day: 10,
      verses: [
        {
          completed: false,
          text: "1 Mos 24:1-67",
        },
        {
          completed: false,
          text: " Matt 9:14-38",
        },
        {
          completed: false,
          text: " Ps 8:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 11,
      verses: [
        {
          completed: false,
          text: "1 Mos 25:1-26:35",
        },
        {
          completed: false,
          text: " Matt 10:1-31",
        },
        {
          completed: false,
          text: " Ords 1:20-33",
        },
      ],
    },
    {
      completed: false,
      day: 12,
      verses: [
        {
          completed: false,
          text: "1 Mos 27:1-28:22",
        },
        {
          completed: false,
          text: " Matt 10:32-11:15",
        },
        {
          completed: false,
          text: " Ps 9:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 13,
      verses: [
        {
          completed: false,
          text: "1 Mos 29:1-30:43",
        },
        {
          completed: false,
          text: " Matt 11:16-30",
        },
        {
          completed: false,
          text: " Ps 9:7-12",
        },
      ],
    },
    {
      completed: false,
      day: 14,
      verses: [
        {
          completed: false,
          text: "1 Mos 31:1-55",
        },
        {
          completed: false,
          text: " Matt 12:1-21",
        },
        {
          completed: false,
          text: " Ps 9:13-20",
        },
      ],
    },
    {
      completed: false,
      day: 15,
      verses: [
        {
          completed: false,
          text: "1 Mos 32:1-33:20",
        },
        {
          completed: false,
          text: " Matt 12:22-45",
        },
        {
          completed: false,
          text: " Ords 2:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 16,
      verses: [
        {
          completed: false,
          text: "1 Mos 34:1-35:29",
        },
        {
          completed: false,
          text: " Matt 12:46-13:7",
        },
        {
          completed: false,
          text: " Ps 10:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 17,
      verses: [
        {
          completed: false,
          text: "1 Mos 36:1-37:36",
        },
        {
          completed: false,
          text: " Matt 13:18-35",
        },
        {
          completed: false,
          text: " Ps 10:12-18",
        },
      ],
    },
    {
      completed: false,
      day: 18,
      verses: [
        {
          completed: false,
          text: "1 Mos 38:1-39:23",
        },
        {
          completed: false,
          text: " Matt 13:36-58",
        },
        {
          completed: false,
          text: " Ps 11:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 19,
      verses: [
        {
          completed: false,
          text: "1 Mos 40:1-41:40",
        },
        {
          completed: false,
          text: " Matt 14:1-21",
        },
        {
          completed: false,
          text: " Ords 2:12-22",
        },
      ],
    },
    {
      completed: false,
      day: 20,
      verses: [
        {
          completed: false,
          text: "1 Mos 41:41-42:38",
        },
        {
          completed: false,
          text: " Matt 14:22-15:9",
        },
        {
          completed: false,
          text: " Ps 12:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 21,
      verses: [
        {
          completed: false,
          text: "1 Mos 43:1-44:34",
        },
        {
          completed: false,
          text: " Matt 15:10-39",
        },
        {
          completed: false,
          text: " Ps 13:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 22,
      verses: [
        {
          completed: false,
          text: "1 Mos 45:1-47:12",
        },
        {
          completed: false,
          text: " Matt 16:1-20",
        },
        {
          completed: false,
          text: " Ps 14:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 23,
      verses: [
        {
          completed: false,
          text: "1 Mos 47:13-48:22",
        },
        {
          completed: false,
          text: " Matt 16:21-17:13",
        },
        {
          completed: false,
          text: " Ords 3:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 24,
      verses: [
        {
          completed: false,
          text: "1 Mos 49:1-50:26",
        },
        {
          completed: false,
          text: " Matt 17:14-18:9",
        },
        {
          completed: false,
          text: " Ps 15:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 25,
      verses: [
        {
          completed: false,
          text: "Job 1:1-3:26",
        },
        {
          completed: false,
          text: " Matt 18:10-35",
        },
        {
          completed: false,
          text: " Ps 16:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 26,
      verses: [
        {
          completed: false,
          text: "Job 4:1-7:21",
        },
        {
          completed: false,
          text: " Matt 19:1-15",
        },
        {
          completed: false,
          text: " Ps 17:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 27,
      verses: [
        {
          completed: false,
          text: "Job 8:1-10:22",
        },
        {
          completed: false,
          text: " Matt 19:16-30",
        },
        {
          completed: false,
          text: " Ords 3:11-20",
        },
      ],
    },
    {
      completed: false,
      day: 28,
      verses: [
        {
          completed: false,
          text: "Job 11:1-14:22",
        },
        {
          completed: false,
          text: " Matt 20:1-19",
        },
        {
          completed: false,
          text: " Ps 17:6-12",
        },
      ],
    },
    {
      completed: false,
      day: 29,
      verses: [
        {
          completed: false,
          text: "Job 15:1-18:21",
        },
        {
          completed: false,
          text: " Matt 20:20-34",
        },
        {
          completed: false,
          text: " Ps 17:13-15",
        },
      ],
    },
    {
      completed: false,
      day: 30,
      verses: [
        {
          completed: false,
          text: "Job 19:1-21:34",
        },
        {
          completed: false,
          text: " Matt 21:1-17",
        },
        {
          completed: false,
          text: " Ps 18:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 31,
      verses: [
        {
          completed: false,
          text: "Job 22:1-24:25",
        },
        {
          completed: false,
          text: " Matt 21:18-32",
        },
        {
          completed: false,
          text: " Ords 3:21-35",
        },
      ],
    },
    {
      completed: false,
      day: 32,
      verses: [
        {
          completed: false,
          text: "Job 25:1-29:25",
        },
        {
          completed: false,
          text: " Matt 21:33-22:14",
        },
        {
          completed: false,
          text: " Ps 18:7-15",
        },
      ],
    },
    {
      completed: false,
      day: 33,
      verses: [
        {
          completed: false,
          text: "Job 30:1-32:22",
        },
        {
          completed: false,
          text: " Matt 22:15-46",
        },
        {
          completed: false,
          text: " Ps 18:16-24",
        },
      ],
    },
    {
      completed: false,
      day: 34,
      verses: [
        {
          completed: false,
          text: "Job 33:1-34:37",
        },
        {
          completed: false,
          text: " Matt 23:1-39",
        },
        {
          completed: false,
          text: " Ps 18:25-36",
        },
      ],
    },
    {
      completed: false,
      day: 35,
      verses: [
        {
          completed: false,
          text: "Job 35:1-37:24",
        },
        {
          completed: false,
          text: " Matt 24:1-31",
        },
        {
          completed: false,
          text: " Ords 4:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 36,
      verses: [
        {
          completed: false,
          text: "Job 38:1-40:2",
        },
        {
          completed: false,
          text: " Matt 24:32-25:13",
        },
        {
          completed: false,
          text: " Ps 18:37-42",
        },
      ],
    },
    {
      completed: false,
      day: 37,
      verses: [
        {
          completed: false,
          text: "Job 40:3-42:17",
        },
        {
          completed: false,
          text: " Matt 25:14-46",
        },
        {
          completed: false,
          text: " Ps 18:43-50",
        },
      ],
    },
    {
      completed: false,
      day: 38,
      verses: [
        {
          completed: false,
          text: "2 Mos 1:1-3:22",
        },
        {
          completed: false,
          text: " Matt 26:1-30",
        },
        {
          completed: false,
          text: " Ps 19:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 39,
      verses: [
        {
          completed: false,
          text: "2 Mos 4:1-6:12",
        },
        {
          completed: false,
          text: " Matt 26:31-46",
        },
        {
          completed: false,
          text: " Ords 4:10-19",
        },
      ],
    },
    {
      completed: false,
      day: 40,
      verses: [
        {
          completed: false,
          text: "2 Mos 6:13-8:32",
        },
        {
          completed: false,
          text: " Matt 26:47-68",
        },
        {
          completed: false,
          text: " Ps 19:7-14",
        },
      ],
    },
    {
      completed: false,
      day: 41,
      verses: [
        {
          completed: false,
          text: "2 Mos 9:1-10:29",
        },
        {
          completed: false,
          text: " Matt 26:69-27:10",
        },
        {
          completed: false,
          text: " Ps 20:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 42,
      verses: [
        {
          completed: false,
          text: "2 Mos 11:1-12:51",
        },
        {
          completed: false,
          text: " Matt 27:11-44",
        },
        {
          completed: false,
          text: " Ps 21:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 43,
      verses: [
        {
          completed: false,
          text: "2 Mos 13:1-14:31",
        },
        {
          completed: false,
          text: " Matt 27:45-66",
        },
        {
          completed: false,
          text: " Ords 4:20-27",
        },
      ],
    },
    {
      completed: false,
      day: 44,
      verses: [
        {
          completed: false,
          text: "2 Mos 15:1-16:36",
        },
        {
          completed: false,
          text: " Matt 28:1-20",
        },
        {
          completed: false,
          text: " Ps 21:8-13",
        },
      ],
    },
    {
      completed: false,
      day: 45,
      verses: [
        {
          completed: false,
          text: "2 Mos 17:1-18:27",
        },
        {
          completed: false,
          text: " Mark 1:1-28",
        },
        {
          completed: false,
          text: " Ps 22:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 46,
      verses: [
        {
          completed: false,
          text: "2 Mos 19:1-20:26",
        },
        {
          completed: false,
          text: " Mark 1:29-2:17",
        },
        {
          completed: false,
          text: "Ps 22:12-21",
        },
      ],
    },
    {
      completed: false,
      day: 47,
      verses: [
        {
          completed: false,
          text: "2 Mos 21:-22:31",
        },
        {
          completed: false,
          text: " Mark 2:18-3:30",
        },
        {
          completed: false,
          text: " Ords 5:1-14",
        },
      ],
    },
    {
      completed: false,
      day: 48,
      verses: [
        {
          completed: false,
          text: "2 Mos 23:1-24:18",
        },
        {
          completed: false,
          text: " Mark 3:31-4:29",
        },
        {
          completed: false,
          text: " Ps 22:22-31",
        },
      ],
    },
    {
      completed: false,
      day: 49,
      verses: [
        {
          completed: false,
          text: "2 Mos 25:1-26:37",
        },
        {
          completed: false,
          text: " Mark 4:30-5:20",
        },
        {
          completed: false,
          text: " Ps 23:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 50,
      verses: [
        {
          completed: false,
          text: "2 Mos 27:1-28:43",
        },
        {
          completed: false,
          text: " Mark 5:21-6:6a",
        },
        {
          completed: false,
          text: " Ps 24:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 51,
      verses: [
        {
          completed: false,
          text: "2 Mos 29:1-30:38",
        },
        {
          completed: false,
          text: " Mark 6:6b-29",
        },
        {
          completed: false,
          text: " Ords 5:15-23",
        },
      ],
    },
    {
      completed: false,
      day: 52,
      verses: [
        {
          completed: false,
          text: "2 Mos 31:1-33:6",
        },
        {
          completed: false,
          text: " Mark 6:30-56",
        },
        {
          completed: false,
          text: " Ps 25:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 53,
      verses: [
        {
          completed: false,
          text: "2 Mos 33:7-34:35",
        },
        {
          completed: false,
          text: " Mark 7:1-30",
        },
        {
          completed: false,
          text: " Ps 25:8-15",
        },
      ],
    },
    {
      completed: false,
      day: 54,
      verses: [
        {
          completed: false,
          text: "2 Mos 35:1-36:38",
        },
        {
          completed: false,
          text: " Mark 7:31-8:13",
        },
        {
          completed: false,
          text: " Ps 25:16-22",
        },
      ],
    },
    {
      completed: false,
      day: 55,
      verses: [
        {
          completed: false,
          text: "2 Mos 37:1-38:31",
        },
        {
          completed: false,
          text: " Mark 8:14-9:1",
        },
        {
          completed: false,
          text: " Ords 6:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 56,
      verses: [
        {
          completed: false,
          text: "2 Mos 39:1-40:38",
        },
        {
          completed: false,
          text: " Mark 9:2-32",
        },
        {
          completed: false,
          text: " Ps 26:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 57,
      verses: [
        {
          completed: false,
          text: "3 Mos 1:1-3:17",
        },
        {
          completed: false,
          text: " Mark 9:33-10:12",
        },
        {
          completed: false,
          text: " Ps 27:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 58,
      verses: [
        {
          completed: false,
          text: "3 Mos 4:1-5:13",
        },
        {
          completed: false,
          text: " Mark 10:13-31",
        },
        {
          completed: false,
          text: " Ps 27:7-14",
        },
      ],
    },
    {
      completed: false,
      day: 59,
      verses: [
        {
          completed: false,
          text: "3 Mos 5:14-7:10",
        },
        {
          completed: false,
          text: " Mark 10:32-52",
        },
        {
          completed: false,
          text: " Ords 6:12-19",
        },
      ],
    },
    {
      completed: false,
      day: 60,
      verses: [
        {
          completed: false,
          text: "3 Mos 7:11-8:36",
        },
        {
          completed: false,
          text: " Mark 11:1-25",
        },
        {
          completed: false,
          text: " Ps 28:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 61,
      verses: [
        {
          completed: false,
          text: "3 Mos 9:1-10:20",
        },
        {
          completed: false,
          text: " Mark 11:27-12:12",
        },
        {
          completed: false,
          text: " Ps 29:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 62,
      verses: [
        {
          completed: false,
          text: "3 Mos 11:1-12:8",
        },
        {
          completed: false,
          text: " Mark 12:13-27",
        },
        {
          completed: false,
          text: "Ps 30:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 63,
      verses: [
        {
          completed: false,
          text: "3 Mos 13:1-59",
        },
        {
          completed: false,
          text: " Mark 12:28-44",
        },
        {
          completed: false,
          text: " Ords 6:20-29",
        },
      ],
    },
    {
      completed: false,
      day: 64,
      verses: [
        {
          completed: false,
          text: "3 Mos 14:1-57",
        },
        {
          completed: false,
          text: " Mark 13:1-31",
        },
        {
          completed: false,
          text: " Ps 30:8-12",
        },
      ],
    },
    {
      completed: false,
      day: 65,
      verses: [
        {
          completed: false,
          text: "3 Mos 15:1-16:34",
        },
        {
          completed: false,
          text: " Mark 13:32-14:16",
        },
        {
          completed: false,
          text: " Ps 31:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 66,
      verses: [
        {
          completed: false,
          text: "3 Mos 17:1-18:30",
        },
        {
          completed: false,
          text: " Mark 14:17-42",
        },
        {
          completed: false,
          text: " Ps 31:9-18",
        },
      ],
    },
    {
      completed: false,
      day: 67,
      verses: [
        {
          completed: false,
          text: "3 Mos 19:1-20:27",
        },
        {
          completed: false,
          text: " Mark 14:43-72",
        },
        {
          completed: false,
          text: " Ords 6:30-35",
        },
      ],
    },
    {
      completed: false,
      day: 68,
      verses: [
        {
          completed: false,
          text: "3 Mos 21:1-22:33",
        },
        {
          completed: false,
          text: " Mark 15:1-32",
        },
        {
          completed: false,
          text: " Ps 31:19-24",
        },
      ],
    },
    {
      completed: false,
      day: 69,
      verses: [
        {
          completed: false,
          text: "3 Mos 23:1-24:23",
        },
        {
          completed: false,
          text: " Mark 15:33-47",
        },
        {
          completed: false,
          text: " Ps 32:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 70,
      verses: [
        {
          completed: false,
          text: "3 Mos 25:1-26:13",
        },
        {
          completed: false,
          text: " Mark 16:1-20",
        },
        {
          completed: false,
          text: " Ps 33:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 71,
      verses: [
        {
          completed: false,
          text: "3 Mos 26:14-27:34",
        },
        {
          completed: false,
          text: " Luk 1:1-25",
        },
        {
          completed: false,
          text: " Ords 7:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 72,
      verses: [
        {
          completed: false,
          text: "4 Mos 1:1-2:9",
        },
        {
          completed: false,
          text: " Luk 1:26-38",
        },
        {
          completed: false,
          text: " Ps 33:12-22",
        },
      ],
    },
    {
      completed: false,
      day: 73,
      verses: [
        {
          completed: false,
          text: "4 Mos 2:10-3:52",
        },
        {
          completed: false,
          text: " Luk 1:39-56",
        },
        {
          completed: false,
          text: " Ps 34:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 74,
      verses: [
        {
          completed: false,
          text: "4 Mos 4:1-5:10",
        },
        {
          completed: false,
          text: " Luk 1:57-80",
        },
        {
          completed: false,
          text: " Ps 34:11-22",
        },
      ],
    },
    {
      completed: false,
      day: 75,
      verses: [
        {
          completed: false,
          text: "4 Mos 5:11-6:27",
        },
        {
          completed: false,
          text: " Luk 2:1-20",
        },
        {
          completed: false,
          text: " Ords 7:6-20",
        },
      ],
    },
    {
      completed: false,
      day: 76,
      verses: [
        {
          completed: false,
          text: "4 Mos 7:1-65",
        },
        {
          completed: false,
          text: " Luk 2:21-40",
        },
        {
          completed: false,
          text: " Ps 35:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 77,
      verses: [
        {
          completed: false,
          text: "4 Mos 7:66-9:14",
        },
        {
          completed: false,
          text: " Luk 2:41-52",
        },
        {
          completed: false,
          text: " Ps 35:11-18",
        },
      ],
    },
    {
      completed: false,
      day: 78,
      verses: [
        {
          completed: false,
          text: "4 Mos 9:15-11:3",
        },
        {
          completed: false,
          text: " Luk 3:1-22",
        },
        {
          completed: false,
          text: " Ps 35:19-28",
        },
      ],
    },
    {
      completed: false,
      day: 79,
      verses: [
        {
          completed: false,
          text: "4 Mos 11:4-13:25",
        },
        {
          completed: false,
          text: " Luk 3:23-4:13",
        },
        {
          completed: false,
          text: " Ords 7:21-27",
        },
      ],
    },
    {
      completed: false,
      day: 80,
      verses: [
        {
          completed: false,
          text: "4 Mos 13:26-14:45",
        },
        {
          completed: false,
          text: " Luk 4:14-37",
        },
        {
          completed: false,
          text: " Ps 36:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 81,
      verses: [
        {
          completed: false,
          text: "4 Mos 15:1-16:35",
        },
        {
          completed: false,
          text: " Luk 4:38-5:16",
        },
        {
          completed: false,
          text: " Ps 37:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 82,
      verses: [
        {
          completed: false,
          text: "4 Mos 16:36-18:32",
        },
        {
          completed: false,
          text: " Luk 5:17-32",
        },
        {
          completed: false,
          text: " Ps 37:10-20",
        },
      ],
    },
    {
      completed: false,
      day: 83,
      verses: [
        {
          completed: false,
          text: "4 Mos 19:1-21:3",
        },
        {
          completed: false,
          text: " Luk 5:33-6:11",
        },
        {
          completed: false,
          text: " Ords 8:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 84,
      verses: [
        {
          completed: false,
          text: "4 Mos 21:4-22:20",
        },
        {
          completed: false,
          text: " Luk 6:12-36",
        },
        {
          completed: false,
          text: " Ps 37:21-31",
        },
      ],
    },
    {
      completed: false,
      day: 85,
      verses: [
        {
          completed: false,
          text: "4 Mos 22:21-23:26",
        },
        {
          completed: false,
          text: " Luk 6:37-7:10",
        },
        {
          completed: false,
          text: " Ps 37:32-40",
        },
      ],
    },
    {
      completed: false,
      day: 86,
      verses: [
        {
          completed: false,
          text: "4 Mos 23:27-26:11",
        },
        {
          completed: false,
          text: " Luk 7:11-35",
        },
        {
          completed: false,
          text: " Ps 38:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 87,
      verses: [
        {
          completed: false,
          text: "4 Mos 26:12-27:11",
        },
        {
          completed: false,
          text: " Luk 7:36-50",
        },
        {
          completed: false,
          text: " Ords 8:12-21",
        },
      ],
    },
    {
      completed: false,
      day: 88,
      verses: [
        {
          completed: false,
          text: "4 Mos 27:12-29:11",
        },
        {
          completed: false,
          text: " Luk 8:1-18",
        },
        {
          completed: false,
          text: " Ps 38:13-22",
        },
      ],
    },
    {
      completed: false,
      day: 89,
      verses: [
        {
          completed: false,
          text: "4 Mos 29:12-31:24",
        },
        {
          completed: false,
          text: " Luk 8:19-39",
        },
        {
          completed: false,
          text: " Ps 39:1-13",
        },
      ],
    },
    {
      completed: false,
      day: 90,
      verses: [
        {
          completed: false,
          text: "4 Mos 31:25-32:42",
        },
        {
          completed: false,
          text: " Luk 8:40-9:9",
        },
        {
          completed: false,
          text: " Ps 40:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 91,
      verses: [
        {
          completed: false,
          text: "4 Mos 33:1-34:29",
        },
        {
          completed: false,
          text: " Luk 9:10-27",
        },
        {
          completed: false,
          text: " Ords 8:22-31",
        },
      ],
    },
    {
      completed: false,
      day: 92,
      verses: [
        {
          completed: false,
          text: "4 Mos 35:1-36:13",
        },
        {
          completed: false,
          text: " Luk 9:28-56",
        },
        {
          completed: false,
          text: " Ps 40:9-17",
        },
      ],
    },
    {
      completed: false,
      day: 93,
      verses: [
        {
          completed: false,
          text: "5 Mos 1:1-2:23",
        },
        {
          completed: false,
          text: " Luk 9:57-10:24",
        },
        {
          completed: false,
          text: " Ps 41:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 94,
      verses: [
        {
          completed: false,
          text: "5 Mos 2:24-4:14",
        },
        {
          completed: false,
          text: " Luk 10:25-11:4",
        },
        {
          completed: false,
          text: " Ps 41:7-13",
        },
      ],
    },
    {
      completed: false,
      day: 95,
      verses: [
        {
          completed: false,
          text: "5 Mos 4:15-5:33",
        },
        {
          completed: false,
          text: " Luk 11:5-32",
        },
        {
          completed: false,
          text: " Ords 8:32-36",
        },
      ],
    },
    {
      completed: false,
      day: 96,
      verses: [
        {
          completed: false,
          text: "5 Mos 6:1-8:20",
        },
        {
          completed: false,
          text: " Luk 11:33-54",
        },
        {
          completed: false,
          text: " Ps 42:1-6a",
        },
      ],
    },
    {
      completed: false,
      day: 97,
      verses: [
        {
          completed: false,
          text: "5 Mos 9:1-10:22",
        },
        {
          completed: false,
          text: " Luk 12:1-34",
        },
        {
          completed: false,
          text: " Ps 42:6b-11",
        },
      ],
    },
    {
      completed: false,
      day: 98,
      verses: [
        {
          completed: false,
          text: "5 Mos 11:1-12:32",
        },
        {
          completed: false,
          text: " Luk 12:35-59",
        },
        {
          completed: false,
          text: " Ps 43:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 99,
      verses: [
        {
          completed: false,
          text: "5 Mos 13:1-14:29",
        },
        {
          completed: false,
          text: " Luk 13:1-30",
        },
        {
          completed: false,
          text: " Ords 9:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 100,
      verses: [
        {
          completed: false,
          text: "5 Mos 15:1-16:20",
        },
        {
          completed: false,
          text: " Luk 13:31-14:14",
        },
        {
          completed: false,
          text: " Ps 44:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 101,
      verses: [
        {
          completed: false,
          text: "5 Mos 16:21-18:22",
        },
        {
          completed: false,
          text: " Luk 14:15-35",
        },
        {
          completed: false,
          text: " Ps 44:13-26",
        },
      ],
    },
    {
      completed: false,
      day: 102,
      verses: [
        {
          completed: false,
          text: "5 Mos 19:1-20:20",
        },
        {
          completed: false,
          text: " Luk 15:1-32",
        },
        {
          completed: false,
          text: " Ps 45:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 103,
      verses: [
        {
          completed: false,
          text: "5 Mos 21:1-22:30",
        },
        {
          completed: false,
          text: " Luk 16:1-18",
        },
        {
          completed: false,
          text: " Ords 9:13-18",
        },
      ],
    },
    {
      completed: false,
      day: 104,
      verses: [
        {
          completed: false,
          text: "5 Mos 23:1-25:19",
        },
        {
          completed: false,
          text: " Luk 16:19-17:10",
        },
        {
          completed: false,
          text: " Ps 45:10-17",
        },
      ],
    },
    {
      completed: false,
      day: 105,
      verses: [
        {
          completed: false,
          text: "5 Mos 26:1-28:14",
        },
        {
          completed: false,
          text: " Luk 17:11-37",
        },
        {
          completed: false,
          text: " Ps 46:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 106,
      verses: [
        {
          completed: false,
          text: "5 Mos 28:15-68",
        },
        {
          completed: false,
          text: " Luk 18:1-30",
        },
        {
          completed: false,
          text: " Ps 47:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 107,
      verses: [
        {
          completed: false,
          text: "5 Mos 29:1-30:10",
        },
        {
          completed: false,
          text: " Luk 18:31-19:10",
        },
        {
          completed: false,
          text: " Ords 10:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 108,
      verses: [
        {
          completed: false,
          text: "5 Mos 30:11-31:29",
        },
        {
          completed: false,
          text: " Luk 19:11-44",
        },
        {
          completed: false,
          text: " Ps 48:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 109,
      verses: [
        {
          completed: false,
          text: "5 Mos 31:30-32:52",
        },
        {
          completed: false,
          text: " Luk 19:45-20:26",
        },
        {
          completed: false,
          text: " Ps 48:9-14",
        },
      ],
    },
    {
      completed: false,
      day: 110,
      verses: [
        {
          completed: false,
          text: "5 Mos 33:1-34:12",
        },
        {
          completed: false,
          text: " Luk 20:27-21:4",
        },
        {
          completed: false,
          text: " Ps 49:1-20",
        },
      ],
    },
    {
      completed: false,
      day: 111,
      verses: [
        {
          completed: false,
          text: "Jos 1:1-2:24",
        },
        {
          completed: false,
          text: " Luk 21:5-38",
        },
        {
          completed: false,
          text: " Ords 10:11-20",
        },
      ],
    },
    {
      completed: false,
      day: 112,
      verses: [
        {
          completed: false,
          text: "Jos 3:1-5:12",
        },
        {
          completed: false,
          text: " Luk 22:1-38",
        },
        {
          completed: false,
          text: " Ps 50:1-15",
        },
      ],
    },
    {
      completed: false,
      day: 113,
      verses: [
        {
          completed: false,
          text: "Jos 5:13-7:26",
        },
        {
          completed: false,
          text: " Luk 22:39-62",
        },
        {
          completed: false,
          text: " Ps 50:16-23",
        },
      ],
    },
    {
      completed: false,
      day: 114,
      verses: [
        {
          completed: false,
          text: "Jos 8:1-9:15",
        },
        {
          completed: false,
          text: " Luk 22:63-23:25",
        },
        {
          completed: false,
          text: " Ps 51:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 115,
      verses: [
        {
          completed: false,
          text: "Jos 9:16-10:43",
        },
        {
          completed: false,
          text: " Luk 23:26-56",
        },
        {
          completed: false,
          text: " Ords 10:21-30",
        },
      ],
    },
    {
      completed: false,
      day: 116,
      verses: [
        {
          completed: false,
          text: "Jos 11:1-12:24",
        },
        {
          completed: false,
          text: " Luk 24:1-35",
        },
        {
          completed: false,
          text: " Ps 51:10-19",
        },
      ],
    },
    {
      completed: false,
      day: 117,
      verses: [
        {
          completed: false,
          text: "Jos 13:1-14:15",
        },
        {
          completed: false,
          text: " Luk 24:36-53",
        },
        {
          completed: false,
          text: " Ps 52:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 118,
      verses: [
        {
          completed: false,
          text: "Jos 15:1-16:10",
        },
        {
          completed: false,
          text: " Joh 1:1-28",
        },
        {
          completed: false,
          text: " Ps 53:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 119,
      verses: [
        {
          completed: false,
          text: "Jos 17:1-18:28",
        },
        {
          completed: false,
          text: " Joh 1:29-51",
        },
        {
          completed: false,
          text: " Ords 10:31-11:8",
        },
      ],
    },
    {
      completed: false,
      day: 120,
      verses: [
        {
          completed: false,
          text: "Jos 19:1-21:19",
        },
        {
          completed: false,
          text: " Joh 2:1-25",
        },
        {
          completed: false,
          text: " Ps 54:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 121,
      verses: [
        {
          completed: false,
          text: "Jos 21:20-22:34",
        },
        {
          completed: false,
          text: " Joh 3:1-21",
        },
        {
          completed: false,
          text: " Ps 55:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 122,
      verses: [
        {
          completed: false,
          text: "Jos 23:1-24:33",
        },
        {
          completed: false,
          text: " Joh 3:22-36",
        },
        {
          completed: false,
          text: " Ps 55:12-23",
        },
      ],
    },
    {
      completed: false,
      day: 123,
      verses: [
        {
          completed: false,
          text: "Dom 1:1-2:5",
        },
        {
          completed: false,
          text: " Joh 4:1-26",
        },
        {
          completed: false,
          text: " Ords 11:9-18",
        },
      ],
    },
    {
      completed: false,
      day: 124,
      verses: [
        {
          completed: false,
          text: "Dom 2:6-3:31",
        },
        {
          completed: false,
          text: " Joh 4:27-42",
        },
        {
          completed: false,
          text: " Ps 56:1-13",
        },
      ],
    },
    {
      completed: false,
      day: 125,
      verses: [
        {
          completed: false,
          text: "Dom 4:1-5:31",
        },
        {
          completed: false,
          text: " Joh 4:43-5:15",
        },
        {
          completed: false,
          text: " Ps 57:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 126,
      verses: [
        {
          completed: false,
          text: "Dom 6:1-7:8a",
        },
        {
          completed: false,
          text: " Joh 5:16-30",
        },
        {
          completed: false,
          text: " Ps 57:7-11",
        },
      ],
    },
    {
      completed: false,
      day: 127,
      verses: [
        {
          completed: false,
          text: "Dom 7:8b-35",
        },
        {
          completed: false,
          text: " Joh 5:31-47",
        },
        {
          completed: false,
          text: " Ords 11:19-28",
        },
      ],
    },
    {
      completed: false,
      day: 128,
      verses: [
        {
          completed: false,
          text: "Dom 9:1-57",
        },
        {
          completed: false,
          text: " Joh 6:1-24",
        },
        {
          completed: false,
          text: " Ps 58:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 129,
      verses: [
        {
          completed: false,
          text: "Dom 10:1-11:40",
        },
        {
          completed: false,
          text: " Joh 6:25-59",
        },
        {
          completed: false,
          text: " Ps 59:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 130,
      verses: [
        {
          completed: false,
          text: "Dom 12:1-13:25",
        },
        {
          completed: false,
          text: " Joh 6:60-7:13",
        },
        {
          completed: false,
          text: " Ps 59:9-17",
        },
      ],
    },
    {
      completed: false,
      day: 131,
      verses: [
        {
          completed: false,
          text: "Dom 14:1-15:20",
        },
        {
          completed: false,
          text: " Joh 7:14-44",
        },
        {
          completed: false,
          text: " Ords 11:29-12:7",
        },
      ],
    },
    {
      completed: false,
      day: 132,
      verses: [
        {
          completed: false,
          text: "Dom 16:1-17:13",
        },
        {
          completed: false,
          text: " Joh 7:45-8:11",
        },
        {
          completed: false,
          text: " Ps 60:1-4",
        },
      ],
    },
    {
      completed: false,
      day: 133,
      verses: [
        {
          completed: false,
          text: "Dom 18:1-19:30",
        },
        {
          completed: false,
          text: " Joh 8:12-30",
        },
        {
          completed: false,
          text: " Ps 60:5-12",
        },
      ],
    },
    {
      completed: false,
      day: 134,
      verses: [
        {
          completed: false,
          text: "Dom 20:1-21:25",
        },
        {
          completed: false,
          text: " Joh 8:31-59",
        },
        {
          completed: false,
          text: " Ps 61:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 135,
      verses: [
        {
          completed: false,
          text: "Rut 1:1-2:23",
        },
        {
          completed: false,
          text: " Joh 9:1-34",
        },
        {
          completed: false,
          text: " Ords 12:8-17",
        },
      ],
    },
    {
      completed: false,
      day: 136,
      verses: [
        {
          completed: false,
          text: "Rut 3:1-4:22",
        },
        {
          completed: false,
          text: " Joh 9:35-10:21",
        },
        {
          completed: false,
          text: " Ps 62:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 137,
      verses: [
        {
          completed: false,
          text: "1 Sam 1:1-2:26",
        },
        {
          completed: false,
          text: " Joh 10:22-42",
        },
        {
          completed: false,
          text: " Ps 63:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 138,
      verses: [
        {
          completed: false,
          text: "1 Sam 2:27-4:22",
        },
        {
          completed: false,
          text: " Joh 11:1-44",
        },
        {
          completed: false,
          text: " Ps 64:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 139,
      verses: [
        {
          completed: false,
          text: "1 Sam 5:1-7:17",
        },
        {
          completed: false,
          text: " Joh 11:45-12:11",
        },
        {
          completed: false,
          text: " Ords 12:18-27",
        },
      ],
    },
    {
      completed: false,
      day: 140,
      verses: [
        {
          completed: false,
          text: "1 Sam 8:1-10:8",
        },
        {
          completed: false,
          text: " Joh 12:12-36",
        },
        {
          completed: false,
          text: " Ps 65:1-13",
        },
      ],
    },
    {
      completed: false,
      day: 141,
      verses: [
        {
          completed: false,
          text: "1 Sam 10:9-12:25",
        },
        {
          completed: false,
          text: " Joh 12:37-13:17",
        },
        {
          completed: false,
          text: " Ps 66:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 142,
      verses: [
        {
          completed: false,
          text: "1 Sam 13:1-14:23",
        },
        {
          completed: false,
          text: " Joh 13:18-38",
        },
        {
          completed: false,
          text: " Ps 66:13-20",
        },
      ],
    },
    {
      completed: false,
      day: 143,
      verses: [
        {
          completed: false,
          text: "1 Sam 14:24-15:35",
        },
        {
          completed: false,
          text: " Joh 14:1-31",
        },
        {
          completed: false,
          text: " Ords 12:28-13:9",
        },
      ],
    },
    {
      completed: false,
      day: 144,
      verses: [
        {
          completed: false,
          text: "1 Sam 16:1-17:37",
        },
        {
          completed: false,
          text: " Joh 15:1-16:4",
        },
        {
          completed: false,
          text: " Ps 67:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 145,
      verses: [
        {
          completed: false,
          text: "1 Sam 17:38-18:30",
        },
        {
          completed: false,
          text: " Joh 16:5-17:5",
        },
        {
          completed: false,
          text: " Ps 68:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 146,
      verses: [
        {
          completed: false,
          text: "1 Sam 19:1-20:42",
        },
        {
          completed: false,
          text: " Joh 17:6-26",
        },
        {
          completed: false,
          text: " Ps 68:7-14",
        },
      ],
    },
    {
      completed: false,
      day: 147,
      verses: [
        {
          completed: false,
          text: "1 Sam 21:1-23:29",
        },
        {
          completed: false,
          text: " Joh 18:1-24",
        },
        {
          completed: false,
          text: " Ords 13:10-19",
        },
      ],
    },
    {
      completed: false,
      day: 148,
      verses: [
        {
          completed: false,
          text: "1 Sam 24:1-25:44",
        },
        {
          completed: false,
          text: " Joh 18:25-40",
        },
        {
          completed: false,
          text: " Ps 68:15-20",
        },
      ],
    },
    {
      completed: false,
      day: 149,
      verses: [
        {
          completed: false,
          text: "1 Sam 26:1-28:25",
        },
        {
          completed: false,
          text: " Joh 19:1-27",
        },
        {
          completed: false,
          text: " Ps 68:21-27",
        },
      ],
    },
    {
      completed: false,
      day: 150,
      verses: [
        {
          completed: false,
          text: "1 Sam 29:1-31:13",
        },
        {
          completed: false,
          text: " Joh 19:28-20:9",
        },
        {
          completed: false,
          text: " Ps 68:28-35",
        },
      ],
    },
    {
      completed: false,
      day: 151,
      verses: [
        {
          completed: false,
          text: "2 Sam 1:1-2:7",
        },
        {
          completed: false,
          text: " Joh 20:10-31",
        },
        {
          completed: false,
          text: " Ords 13:20-14:4",
        },
      ],
    },
    {
      completed: false,
      day: 152,
      verses: [
        {
          completed: false,
          text: "2 Sam 2:8-3:21",
        },
        {
          completed: false,
          text: " Joh 21:1-25",
        },
        {
          completed: false,
          text: " Ps 69:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 153,
      verses: [
        {
          completed: false,
          text: "2 Sam 3:22-5:5",
        },
        {
          completed: false,
          text: " Apg 1:1-22",
        },
        {
          completed: false,
          text: " Ps 69:13-28",
        },
      ],
    },
    {
      completed: false,
      day: 154,
      verses: [
        {
          completed: false,
          text: "2 Sam 5:6-6:23",
        },
        {
          completed: false,
          text: " Apg 1:23-2:21",
        },
        {
          completed: false,
          text: " Ps 69:29-36",
        },
      ],
    },
    {
      completed: false,
      day: 155,
      verses: [
        {
          completed: false,
          text: "2 Sam 7:1-8:18",
        },
        {
          completed: false,
          text: " Apg 2:22-47",
        },
        {
          completed: false,
          text: " Ords 14:5-14",
        },
      ],
    },
    {
      completed: false,
      day: 156,
      verses: [
        {
          completed: false,
          text: "2 Sam 9:1-10:19",
        },
        {
          completed: false,
          text: " Apg 3:1-26",
        },
        {
          completed: false,
          text: " Ps 70:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 157,
      verses: [
        {
          completed: false,
          text: "2 Sam 11:1-12:31",
        },
        {
          completed: false,
          text: " Apg 4:1-22",
        },
        {
          completed: false,
          text: " Ps 71:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 158,
      verses: [
        {
          completed: false,
          text: "2 Sam 13:1-39",
        },
        {
          completed: false,
          text: " Apg 4:23-5:11",
        },
        {
          completed: false,
          text: " Ps 71:9-18",
        },
      ],
    },
    {
      completed: false,
      day: 159,
      verses: [
        {
          completed: false,
          text: "2 Sam 14:1-15:12",
        },
        {
          completed: false,
          text: " Apg 5:12-42",
        },
        {
          completed: false,
          text: " Ords 14:15-24",
        },
      ],
    },
    {
      completed: false,
      day: 160,
      verses: [
        {
          completed: false,
          text: "2 Sam 15:13-16:14",
        },
        {
          completed: false,
          text: " Apg 6:1-7:19",
        },
        {
          completed: false,
          text: " Ps 71:19-24",
        },
      ],
    },
    {
      completed: false,
      day: 161,
      verses: [
        {
          completed: false,
          text: "2 Sam 16:15-18:18",
        },
        {
          completed: false,
          text: " Apg 7:20-43",
        },
        {
          completed: false,
          text: " Ps 72:1-20",
        },
      ],
    },
    {
      completed: false,
      day: 162,
      verses: [
        {
          completed: false,
          text: "2 Sam 18:19-19:43",
        },
        {
          completed: false,
          text: " Apg 7:44-8:3",
        },
        {
          completed: false,
          text: " Ps 73:1-14",
        },
      ],
    },
    {
      completed: false,
      day: 163,
      verses: [
        {
          completed: false,
          text: "2 Sam 20:1-21:22",
        },
        {
          completed: false,
          text: " Apg 8:4-40",
        },
        {
          completed: false,
          text: " Ords 14:25-35",
        },
      ],
    },
    {
      completed: false,
      day: 164,
      verses: [
        {
          completed: false,
          text: "2 Sam 22:1-23:7",
        },
        {
          completed: false,
          text: " Apg 9:1-31",
        },
        {
          completed: false,
          text: " Ps 73:15-28",
        },
      ],
    },
    {
      completed: false,
      day: 165,
      verses: [
        {
          completed: false,
          text: "2 Sam 23:8-24:25",
        },
        {
          completed: false,
          text: " Apg 9:32-10:23",
        },
        {
          completed: false,
          text: " Ps 74:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 166,
      verses: [
        {
          completed: false,
          text: "1 Kung 1:1-2:12",
        },
        {
          completed: false,
          text: " Apg 10:24-11:18",
        },
        {
          completed: false,
          text: " Ps 74:10-17",
        },
      ],
    },
    {
      completed: false,
      day: 167,
      verses: [
        {
          completed: false,
          text: "1 Kung 2:13-3:15",
        },
        {
          completed: false,
          text: " Apg 11:19-12:19",
        },
        {
          completed: false,
          text: " Ords 15:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 168,
      verses: [
        {
          completed: false,
          text: "1 Kung 3:16-5:18",
        },
        {
          completed: false,
          text: " Apg 12:20-13:12",
        },
        {
          completed: false,
          text: " Ps 74:18-23",
        },
      ],
    },
    {
      completed: false,
      day: 169,
      verses: [
        {
          completed: false,
          text: "1 Kung 6:1-7:22",
        },
        {
          completed: false,
          text: " Apg 13:13-41",
        },
        {
          completed: false,
          text: " Ps 75:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 170,
      verses: [
        {
          completed: false,
          text: "1 Kung 7:23-8:21",
        },
        {
          completed: false,
          text: " Apg 13:42-14:7",
        },
        {
          completed: false,
          text: " Ps 76:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 171,
      verses: [
        {
          completed: false,
          text: "1 Kung 8:22-9:9",
        },
        {
          completed: false,
          text: " Apg 14:8-28",
        },
        {
          completed: false,
          text: " Ords 15:11-20",
        },
      ],
    },
    {
      completed: false,
      day: 172,
      verses: [
        {
          completed: false,
          text: "1 Kung 9:10-11:13",
        },
        {
          completed: false,
          text: " Apg 15:1-21",
        },
        {
          completed: false,
          text: " Ps 77:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 173,
      verses: [
        {
          completed: false,
          text: "1 Kung 11:14-12:24",
        },
        {
          completed: false,
          text: " Apg 15:22-41",
        },
        {
          completed: false,
          text: " Ps 77:10-20",
        },
      ],
    },
    {
      completed: false,
      day: 174,
      verses: [
        {
          completed: false,
          text: "1 Kung 12:25-14:20",
        },
        {
          completed: false,
          text: " Apg 16:1-15",
        },
        {
          completed: false,
          text: " Ps 78:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 175,
      verses: [
        {
          completed: false,
          text: "1 Kung 14:21-16:7",
        },
        {
          completed: false,
          text: " Apg 16:16-40",
        },
        {
          completed: false,
          text: " Ords 15:21-30",
        },
      ],
    },
    {
      completed: false,
      day: 176,
      verses: [
        {
          completed: false,
          text: "1 Kung 16:8-18:15",
        },
        {
          completed: false,
          text: " Apg 17:1-21",
        },
        {
          completed: false,
          text: " Ps 78:9-16",
        },
      ],
    },
    {
      completed: false,
      day: 177,
      verses: [
        {
          completed: false,
          text: "1 Kung 18:16-19:21",
        },
        {
          completed: false,
          text: " Apg 17:22-18:8",
        },
        {
          completed: false,
          text: " Ps 78:17-31",
        },
      ],
    },
    {
      completed: false,
      day: 178,
      verses: [
        {
          completed: false,
          text: "1 Kung 20:1-21:29",
        },
        {
          completed: false,
          text: " Apg 18:9-19:13",
        },
        {
          completed: false,
          text: " Ps 78:32-39",
        },
      ],
    },
    {
      completed: false,
      day: 179,
      verses: [
        {
          completed: false,
          text: "1 Kung 22:1-53",
        },
        {
          completed: false,
          text: " Apg 19:14-41",
        },
        {
          completed: false,
          text: " Ords 15:31-16:7",
        },
      ],
    },
    {
      completed: false,
      day: 180,
      verses: [
        {
          completed: false,
          text: "2 Kung 1:1-2:25",
        },
        {
          completed: false,
          text: " Apg 20:1-38",
        },
        {
          completed: false,
          text: " Ps 78:40-55",
        },
      ],
    },
    {
      completed: false,
      day: 181,
      verses: [
        {
          completed: false,
          text: "2 Kung 3:1-4:37",
        },
        {
          completed: false,
          text: " Apg 21:1-26",
        },
        {
          completed: false,
          text: " Ps 78:56-72",
        },
      ],
    },
    {
      completed: false,
      day: 182,
      verses: [
        {
          completed: false,
          text: "2 Kung 4:38-6:23",
        },
        {
          completed: false,
          text: " Apg 21:27-22:31",
        },
        {
          completed: false,
          text: " Ps 79:1-13",
        },
      ],
    },
    {
      completed: false,
      day: 183,
      verses: [
        {
          completed: false,
          text: "2 Kung 6:24-8:15",
        },
        {
          completed: false,
          text: " Apg 22:22-23:11",
        },
        {
          completed: false,
          text: " Ords 16:8-17",
        },
      ],
    },
    {
      completed: false,
      day: 184,
      verses: [
        {
          completed: false,
          text: "2 Kung 8:16-9:37",
        },
        {
          completed: false,
          text: " Apg 23:12-35",
        },
        {
          completed: false,
          text: " Ps 80:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 185,
      verses: [
        {
          completed: false,
          text: "2 Kung 10:1-11:21",
        },
        {
          completed: false,
          text: " Apg 24:1-27",
        },
        {
          completed: false,
          text: " Ps 80:8-19",
        },
      ],
    },
    {
      completed: false,
      day: 186,
      verses: [
        {
          completed: false,
          text: "2 Kung 12:1-14:22",
        },
        {
          completed: false,
          text: " Apg 25:1-22",
        },
        {
          completed: false,
          text: " Ps 81:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 187,
      verses: [
        {
          completed: false,
          text: "2 Kung 14:23-15:38",
        },
        {
          completed: false,
          text: " Apg 25:23-26:23",
        },
        {
          completed: false,
          text: " Ords 16:18-27",
        },
      ],
    },
    {
      completed: false,
      day: 188,
      verses: [
        {
          completed: false,
          text: "2 Kung 16:1-17:41",
        },
        {
          completed: false,
          text: " Apg 26:24-27:12",
        },
        {
          completed: false,
          text: " Ps 81:8-16",
        },
      ],
    },
    {
      completed: false,
      day: 189,
      verses: [
        {
          completed: false,
          text: "2 Kung 18:1-19:13",
        },
        {
          completed: false,
          text: " Apg 27:13-44",
        },
        {
          completed: false,
          text: " Ps 82:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 190,
      verses: [
        {
          completed: false,
          text: "2 Kung 19:14-20:21",
        },
        {
          completed: false,
          text: " Apg 28:1-16",
        },
        {
          completed: false,
          text: " Ps 83:1-18",
        },
      ],
    },
    {
      completed: false,
      day: 191,
      verses: [
        {
          completed: false,
          text: "2 Kung 21:1-22:20",
        },
        {
          completed: false,
          text: " Apg 28:17-31",
        },
        {
          completed: false,
          text: " Ords 16:28-17:4",
        },
      ],
    },
    {
      completed: false,
      day: 192,
      verses: [
        {
          completed: false,
          text: "2 Kung 23:1-24:7",
        },
        {
          completed: false,
          text: "Rom 1:1-17",
        },
        {
          completed: false,
          text: "Ps 84:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 193,
      verses: [
        {
          completed: false,
          text: "2 Kung 24:8-25:30",
        },
        {
          completed: false,
          text: " Rom 1:18-32",
        },
        {
          completed: false,
          text: " Ps 84:8-12",
        },
      ],
    },
    {
      completed: false,
      day: 194,
      verses: [
        {
          completed: false,
          text: "Jona 1:1-4:11",
        },
        {
          completed: false,
          text: " Rom 2:1-16",
        },
        {
          completed: false,
          text: "Ps 85:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 195,
      verses: [
        {
          completed: false,
          text: "Amos 1:1-2:16",
        },
        {
          completed: false,
          text: " Rom 2:17-3:8",
        },
        {
          completed: false,
          text: " Ords 17:5-14",
        },
      ],
    },
    {
      completed: false,
      day: 196,
      verses: [
        {
          completed: false,
          text: "Am 3:1-4:13",
        },
        {
          completed: false,
          text: " Rom 3:9-31",
        },
        {
          completed: false,
          text: " Ps 85:8-13",
        },
      ],
    },
    {
      completed: false,
      day: 197,
      verses: [
        {
          completed: false,
          text: "Am 5:1-27",
        },
        {
          completed: false,
          text: " Rom 4:1-15",
        },
        {
          completed: false,
          text: " Ps 86:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 198,
      verses: [
        {
          completed: false,
          text: "Am 6:1-7:17",
        },
        {
          completed: false,
          text: " Rom 4:16-5:11",
        },
        {
          completed: false,
          text: " Ps 86:11-17",
        },
      ],
    },
    {
      completed: false,
      day: 199,
      verses: [
        {
          completed: false,
          text: "Am 8:1-9:15",
        },
        {
          completed: false,
          text: " Rom 5:12-21",
        },
        {
          completed: false,
          text: " Ords 17:15-24",
        },
      ],
    },
    {
      completed: false,
      day: 200,
      verses: [
        {
          completed: false,
          text: "Hos 1:1-2:23",
        },
        {
          completed: false,
          text: " Rom 6:1-14",
        },
        {
          completed: false,
          text: " Ps 87:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 201,
      verses: [
        {
          completed: false,
          text: "Hos 3:1-5:15",
        },
        {
          completed: false,
          text: " Rom 6:15-7:6",
        },
        {
          completed: false,
          text: " Ps 88:1-9a",
        },
      ],
    },
    {
      completed: false,
      day: 202,
      verses: [
        {
          completed: false,
          text: "Hos 6:1-7:16",
        },
        {
          completed: false,
          text: " Rom 7:7-25",
        },
        {
          completed: false,
          text: " Ps 88:9b-18",
        },
      ],
    },
    {
      completed: false,
      day: 203,
      verses: [
        {
          completed: false,
          text: "Hos 8:1-9:17",
        },
        {
          completed: false,
          text: " Rom 8:1-17",
        },
        {
          completed: false,
          text: " Ords 17:25-18:6",
        },
      ],
    },
    {
      completed: false,
      day: 204,
      verses: [
        {
          completed: false,
          text: "Hos 10:1-11:11",
        },
        {
          completed: false,
          text: " Rom 8:18-39",
        },
        {
          completed: false,
          text: " Ps 89:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 205,
      verses: [
        {
          completed: false,
          text: "Hos 11:12-14:9",
        },
        {
          completed: false,
          text: " Rom 9:1-21",
        },
        {
          completed: false,
          text: " Ps 89:9-13",
        },
      ],
    },
    {
      completed: false,
      day: 206,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 1:1-2:17",
        },
        {
          completed: false,
          text: " Rom 9:22-10:4",
        },
        {
          completed: false,
          text: " Ps 89:14-18",
        },
      ],
    },
    {
      completed: false,
      day: 207,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 2:18-4:8",
        },
        {
          completed: false,
          text: " Rom 10:5-11:10",
        },
        {
          completed: false,
          text: " Ords 18:7-16",
        },
      ],
    },
    {
      completed: false,
      day: 208,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 4:9-5:26",
        },
        {
          completed: false,
          text: " Rom 11:11-32",
        },
        {
          completed: false,
          text: " Ps 89:19-29",
        },
      ],
    },
    {
      completed: false,
      day: 209,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 6:1-81",
        },
        {
          completed: false,
          text: " Rom 11:33-12:21",
        },
        {
          completed: false,
          text: " Ps 89:30-37",
        },
      ],
    },
    {
      completed: false,
      day: 210,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 7:1-9:1",
        },
        {
          completed: false,
          text: " Rom 13:1-14",
        },
        {
          completed: false,
          text: " Ps 89:38-45",
        },
      ],
    },
    {
      completed: false,
      day: 211,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 9:2-10:14",
        },
        {
          completed: false,
          text: " Rom 14:1-18",
        },
        {
          completed: false,
          text: " Ords 18:17-19:2",
        },
      ],
    },
    {
      completed: false,
      day: 212,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 11:1-12:22",
        },
        {
          completed: false,
          text: " Rom 14:19-15:13",
        },
        {
          completed: false,
          text: " Ps 89:46-52",
        },
      ],
    },
    {
      completed: false,
      day: 213,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 12:23-14:17",
        },
        {
          completed: false,
          text: " Rom 15:14-33",
        },
        {
          completed: false,
          text: " Ps 90:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 214,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 15:1-16:36",
        },
        {
          completed: false,
          text: " Rom 16:1-27",
        },
        {
          completed: false,
          text: " Ps 90:11-17",
        },
      ],
    },
    {
      completed: false,
      day: 215,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 16:37-18:17",
        },
        {
          completed: false,
          text: " 1 Kor 1:1-17",
        },
        {
          completed: false,
          text: " Ords 19:3-12",
        },
      ],
    },
    {
      completed: false,
      day: 216,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 19:1-22:1",
        },
        {
          completed: false,
          text: " 1 Kor 1:18-2:5",
        },
        {
          completed: false,
          text: " Ps 91:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 217,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 22:2-23:32",
        },
        {
          completed: false,
          text: " 1 Kor 2:6-16",
        },
        {
          completed: false,
          text: " Ps 91:9-16",
        },
      ],
    },
    {
      completed: false,
      day: 218,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 24:1-26:19",
        },
        {
          completed: false,
          text: " 1 Kor 3:1-23",
        },
        {
          completed: false,
          text: " Ps 92:1-15",
        },
      ],
    },
    {
      completed: false,
      day: 219,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 26:20-27:34",
        },
        {
          completed: false,
          text: " 1 Kor 4:1-21",
        },
        {
          completed: false,
          text: " Ords 19:13-22",
        },
      ],
    },
    {
      completed: false,
      day: 220,
      verses: [
        {
          completed: false,
          text: "1 Kr\u00f6n 28:1-29:30",
        },
        {
          completed: false,
          text: " 1 Kor 5:1-13",
        },
        {
          completed: false,
          text: " Ps 93:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 221,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 1:1-17",
        },
        {
          completed: false,
          text: " 1 Kor 6:1-20",
        },
        {
          completed: false,
          text: " Ps 94:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 222,
      verses: [
        {
          completed: false,
          text: "Pred 1:1-3:22",
        },
        {
          completed: false,
          text: " 1 Kor 7:1-16",
        },
        {
          completed: false,
          text: " Ps 94:12-23",
        },
      ],
    },
    {
      completed: false,
      day: 223,
      verses: [
        {
          completed: false,
          text: "Pred 4:1-6:12",
        },
        {
          completed: false,
          text: " 1 Kor 7:17-35",
        },
        {
          completed: false,
          text: " Ords 19:23-20:4",
        },
      ],
    },
    {
      completed: false,
      day: 224,
      verses: [
        {
          completed: false,
          text: "Pred 7:1-9:12",
        },
        {
          completed: false,
          text: " 1 Kor 7:36-8:13",
        },
        {
          completed: false,
          text: " Ps 95:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 225,
      verses: [
        {
          completed: false,
          text: "Pred 9:13-12:14",
        },
        {
          completed: false,
          text: " 1 Kor 9:1-18",
        },
        {
          completed: false,
          text: " Ps 96:1-13",
        },
      ],
    },
    {
      completed: false,
      day: 226,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 2:1-5:1",
        },
        {
          completed: false,
          text: " 1 Kor 9:19-10:13",
        },
        {
          completed: false,
          text: " Ps 97:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 227,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 5:2-7:10",
        },
        {
          completed: false,
          text: " 1 Kor 10:14-11:1",
        },
        {
          completed: false,
          text: " Ords 20:5-14",
        },
      ],
    },
    {
      completed: false,
      day: 228,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 7:11-9:31",
        },
        {
          completed: false,
          text: " 1 Kor 11:2-34",
        },
        {
          completed: false,
          text: " Ps 98:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 229,
      verses: [
        {
          completed: false,
          text: "H\u00f6ga V 1:1-4:16",
        },
        {
          completed: false,
          text: " 1 Kor 12:1-26",
        },
        {
          completed: false,
          text: " Ps 99:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 230,
      verses: [
        {
          completed: false,
          text: "H\u00f6ga V 5:1-8:14",
        },
        {
          completed: false,
          text: " 1 Kor 12:27-13:13",
        },
        {
          completed: false,
          text: " Ps 100:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 231,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n10:1-12:16",
        },
        {
          completed: false,
          text: " 1 Kor 14:1-19",
        },
        {
          completed: false,
          text: " Ords 20:15-24",
        },
      ],
    },
    {
      completed: false,
      day: 232,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 13:1-15:19",
        },
        {
          completed: false,
          text: " 1 Kor 14:20-40",
        },
        {
          completed: false,
          text: " Ps 101:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 233,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 16:1-18:27",
        },
        {
          completed: false,
          text: " 1 Kor 15:1-34",
        },
        {
          completed: false,
          text: " Ps 102:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 234,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 18:28-21:3",
        },
        {
          completed: false,
          text: " 1 Kor 15:35-49",
        },
        {
          completed: false,
          text: " Ps 102:12-17",
        },
      ],
    },
    {
      completed: false,
      day: 235,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 21:4-23:21",
        },
        {
          completed: false,
          text: " 1 Kor 15:50-16:4",
        },
        {
          completed: false,
          text: " Ords 20:25-21:4",
        },
      ],
    },
    {
      completed: false,
      day: 236,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 24:1-25:28",
        },
        {
          completed: false,
          text: " 1 Kor 16:5-24",
        },
        {
          completed: false,
          text: " Ps 102:18-28",
        },
      ],
    },
    {
      completed: false,
      day: 237,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 26:1-28:27",
        },
        {
          completed: false,
          text: " 2 Kor 1:1-11",
        },
        {
          completed: false,
          text: " Ps 103:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 238,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 29:1-31:1",
        },
        {
          completed: false,
          text: " 2 Kor 1:12-22",
        },
        {
          completed: false,
          text: " Ps 103:13-22",
        },
      ],
    },
    {
      completed: false,
      day: 239,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 31:2-33:20",
        },
        {
          completed: false,
          text: " 2 Kor 1:23-2:11",
        },
        {
          completed: false,
          text: " Ords 21:5-16",
        },
      ],
    },
    {
      completed: false,
      day: 240,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 33:21-35:19",
        },
        {
          completed: false,
          text: " 2 Kor 2:12-3:6",
        },
        {
          completed: false,
          text: " Ps 104:1-18",
        },
      ],
    },
    {
      completed: false,
      day: 241,
      verses: [
        {
          completed: false,
          text: "2 Kr\u00f6n 35:20-36:23",
        },
        {
          completed: false,
          text: " 2 Kor 3:7-18",
        },
        {
          completed: false,
          text: " Ps 104:19-30",
        },
      ],
    },
    {
      completed: false,
      day: 242,
      verses: [
        {
          completed: false,
          text: "Mika 1:1-4:13",
        },
        {
          completed: false,
          text: " 2 Kor 4:1-18",
        },
        {
          completed: false,
          text: " Ps 104:31-35",
        },
      ],
    },
    {
      completed: false,
      day: 243,
      verses: [
        {
          completed: false,
          text: "Mika 5:1-7:20",
        },
        {
          completed: false,
          text: " 2 Kor 5:1-10",
        },
        {
          completed: false,
          text: " Ords 21:17-26",
        },
      ],
    },
    {
      completed: false,
      day: 244,
      verses: [
        {
          completed: false,
          text: "Jes 1:1-2:22",
        },
        {
          completed: false,
          text: " 2 Kor 5:11-6:2",
        },
        {
          completed: false,
          text: " Ps 105:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 245,
      verses: [
        {
          completed: false,
          text: "Jes 3:1-5:7",
        },
        {
          completed: false,
          text: " 2 Kor 6:3-7:1",
        },
        {
          completed: false,
          text: " Ps 105:12-22",
        },
      ],
    },
    {
      completed: false,
      day: 246,
      verses: [
        {
          completed: false,
          text: "Jes 5:8-8:10",
        },
        {
          completed: false,
          text: " 2 Kor 7:2-16",
        },
        {
          completed: false,
          text: " Ps 105:23-36",
        },
      ],
    },
    {
      completed: false,
      day: 247,
      verses: [
        {
          completed: false,
          text: "Jes 8:11-10:19",
        },
        {
          completed: false,
          text: " 2 Kor 8:1-15",
        },
        {
          completed: false,
          text: " Ords 21:27-22:6",
        },
      ],
    },
    {
      completed: false,
      day: 248,
      verses: [
        {
          completed: false,
          text: "Jes 10:20-13:22",
        },
        {
          completed: false,
          text: " 2 Kor 8:16-9:5",
        },
        {
          completed: false,
          text: " Ps 105:37-45",
        },
      ],
    },
    {
      completed: false,
      day: 249,
      verses: [
        {
          completed: false,
          text: "Jes 14:1-16:14",
        },
        {
          completed: false,
          text: " 2 Kor 9:6-15",
        },
        {
          completed: false,
          text: " Ps 106:1-15",
        },
      ],
    },
    {
      completed: false,
      day: 250,
      verses: [
        {
          completed: false,
          text: "Jes 17:1-19:25",
        },
        {
          completed: false,
          text: " 2 Kor 10:1-18",
        },
        {
          completed: false,
          text: " Ps 106:16-31",
        },
      ],
    },
    {
      completed: false,
      day: 251,
      verses: [
        {
          completed: false,
          text: "Jes 20:1-23:18",
        },
        {
          completed: false,
          text: " 2 Kor 11:1-15",
        },
        {
          completed: false,
          text: " Ords 22:7-16",
        },
      ],
    },
    {
      completed: false,
      day: 252,
      verses: [
        {
          completed: false,
          text: "Jes 24:1-26:21",
        },
        {
          completed: false,
          text: " 2 Kor 11:16-33",
        },
        {
          completed: false,
          text: " Ps 106:32-39",
        },
      ],
    },
    {
      completed: false,
      day: 253,
      verses: [
        {
          completed: false,
          text: "Jes 27:1-28:29",
        },
        {
          completed: false,
          text: " 2 Kor 12:1-10",
        },
        {
          completed: false,
          text: " Ps 106:40-48",
        },
      ],
    },
    {
      completed: false,
      day: 254,
      verses: [
        {
          completed: false,
          text: "Jes 29:1-30:18",
        },
        {
          completed: false,
          text: " 2 Kor 12:11-21",
        },
        {
          completed: false,
          text: "Ps 107:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 255,
      verses: [
        {
          completed: false,
          text: "Jes 30:19-32:20",
        },
        {
          completed: false,
          text: "2 Kor 13:1-14",
        },
        {
          completed: false,
          text: " Ords 22:17-27",
        },
      ],
    },
    {
      completed: false,
      day: 256,
      verses: [
        {
          completed: false,
          text: "Jes 33:1-35:10",
        },
        {
          completed: false,
          text: " Gal 1:1-24",
        },
        {
          completed: false,
          text: " Ps 107:10-22",
        },
      ],
    },
    {
      completed: false,
      day: 257,
      verses: [
        {
          completed: false,
          text: "Jes 36:1-37:38",
        },
        {
          completed: false,
          text: " Gal 2:1-10",
        },
        {
          completed: false,
          text: " Ps 107:23-32",
        },
      ],
    },
    {
      completed: false,
      day: 258,
      verses: [
        {
          completed: false,
          text: "Jes 38:1-40:31",
        },
        {
          completed: false,
          text: " Gal 2:11-3:9",
        },
        {
          completed: false,
          text: " Ps 107:33-43",
        },
      ],
    },
    {
      completed: false,
      day: 259,
      verses: [
        {
          completed: false,
          text: "Jes 41:1-42:25",
        },
        {
          completed: false,
          text: " Gal 3:10-25",
        },
        {
          completed: false,
          text: " Ords 22:28-23:9",
        },
      ],
    },
    {
      completed: false,
      day: 260,
      verses: [
        {
          completed: false,
          text: "Jes 43:1-44:23",
        },
        {
          completed: false,
          text: " Gal 3:26-4:20",
        },
        {
          completed: false,
          text: " Ps 108:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 261,
      verses: [
        {
          completed: false,
          text: "Jes 44:24-46:13",
        },
        {
          completed: false,
          text: " Gal 4:21-5:6",
        },
        {
          completed: false,
          text: " Ps 108:6-13",
        },
      ],
    },
    {
      completed: false,
      day: 262,
      verses: [
        {
          completed: false,
          text: "Jes 47:1-49:7",
        },
        {
          completed: false,
          text: " Gal 5:7-26",
        },
        {
          completed: false,
          text: " Ps 109:1-20",
        },
      ],
    },
    {
      completed: false,
      day: 263,
      verses: [
        {
          completed: false,
          text: "Jes 49:8-51:16",
        },
        {
          completed: false,
          text: " Gal 6:1-18",
        },
        {
          completed: false,
          text: " Ords 23:10-18",
        },
      ],
    },
    {
      completed: false,
      day: 264,
      verses: [
        {
          completed: false,
          text: "Jes 51:17-54:17",
        },
        {
          completed: false,
          text: " Ef 1:1-23",
        },
        {
          completed: false,
          text: " Ps 109:21-31",
        },
      ],
    },
    {
      completed: false,
      day: 265,
      verses: [
        {
          completed: false,
          text: "Jes 55:1-57:13",
        },
        {
          completed: false,
          text: " Ef 2:1-22",
        },
        {
          completed: false,
          text: " Ps 110:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 266,
      verses: [
        {
          completed: false,
          text: "Jes 57:14-59:21",
        },
        {
          completed: false,
          text: " Ef 3:1-21",
        },
        {
          completed: false,
          text: " Ps 111:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 267,
      verses: [
        {
          completed: false,
          text: "Jes 60:1-62:12",
        },
        {
          completed: false,
          text: " Ef 4:1-16",
        },
        {
          completed: false,
          text: " Ords 23:19-28",
        },
      ],
    },
    {
      completed: false,
      day: 268,
      verses: [
        {
          completed: false,
          text: "Jes 63:1-65:16",
        },
        {
          completed: false,
          text: " Ef 4:17-5:7",
        },
        {
          completed: false,
          text: " Ps 112:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 269,
      verses: [
        {
          completed: false,
          text: "Jes 65:17-66:24",
        },
        {
          completed: false,
          text: " Ef 5:8-33",
        },
        {
          completed: false,
          text: " Ps 113:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 270,
      verses: [
        {
          completed: false,
          text: "Nah 1:1-3:19",
        },
        {
          completed: false,
          text: " Ef 6:1-24",
        },
        {
          completed: false,
          text: " Ps 114:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 271,
      verses: [
        {
          completed: false,
          text: "Sef 1:1-3:20",
        },
        {
          completed: false,
          text: " Fil 1:1-26",
        },
        {
          completed: false,
          text: " Ords 23:29-24:4",
        },
      ],
    },
    {
      completed: false,
      day: 272,
      verses: [
        {
          completed: false,
          text: "Jer 1:1-2:30",
        },
        {
          completed: false,
          text: "Fil 1:27-2:11",
        },
        {
          completed: false,
          text: " Ps 115:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 273,
      verses: [
        {
          completed: false,
          text: "Jer 2:31-4:9",
        },
        {
          completed: false,
          text: " Fil 2:12-30",
        },
        {
          completed: false,
          text: " Ps 115:12-18",
        },
      ],
    },
    {
      completed: false,
      day: 274,
      verses: [
        {
          completed: false,
          text: "Jer 4:10-5:31",
        },
        {
          completed: false,
          text: " Fil 3:1-4:1",
        },
        {
          completed: false,
          text: " Ps 116:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 275,
      verses: [
        {
          completed: false,
          text: "Jer 6:1-7:29",
        },
        {
          completed: false,
          text: " Fil 4:2-23",
        },
        {
          completed: false,
          text: " Ords 24:5-14",
        },
      ],
    },
    {
      completed: false,
      day: 276,
      verses: [
        {
          completed: false,
          text: "Jer 7:30-9:16",
        },
        {
          completed: false,
          text: " Kol 1:1-23",
        },
        {
          completed: false,
          text: " Ps 116:12-19",
        },
      ],
    },
    {
      completed: false,
      day: 277,
      verses: [
        {
          completed: false,
          text: "Jer 9:17-11:17",
        },
        {
          completed: false,
          text: " Kol 1:24-2:5",
        },
        {
          completed: false,
          text: " Ps 117:1-2",
        },
      ],
    },
    {
      completed: false,
      day: 278,
      verses: [
        {
          completed: false,
          text: "Jer 11:18-13:27",
        },
        {
          completed: false,
          text: " Kol 2:6-23",
        },
        {
          completed: false,
          text: " Ps 118:1-16",
        },
      ],
    },
    {
      completed: false,
      day: 279,
      verses: [
        {
          completed: false,
          text: "Jer 14:1-15:21",
        },
        {
          completed: false,
          text: " Kol 3:1-4:1",
        },
        {
          completed: false,
          text: " Ords 24:15-22",
        },
      ],
    },
    {
      completed: false,
      day: 280,
      verses: [
        {
          completed: false,
          text: "Jer 16:1-17:27",
        },
        {
          completed: false,
          text: " Kol 4:2-18",
        },
        {
          completed: false,
          text: " Ps 118:17-29",
        },
      ],
    },
    {
      completed: false,
      day: 281,
      verses: [
        {
          completed: false,
          text: "Jer 18:1-20:18",
        },
        {
          completed: false,
          text: " 1 Thess 1:1-2:16",
        },
        {
          completed: false,
          text: " Ps 119:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 282,
      verses: [
        {
          completed: false,
          text: "Jer 21:1-23:8",
        },
        {
          completed: false,
          text: " 1 Thess 2:17-3:13",
        },
        {
          completed: false,
          text: " Ps 119:9-16",
        },
      ],
    },
    {
      completed: false,
      day: 283,
      verses: [
        {
          completed: false,
          text: "Jer 23:9-25:14",
        },
        {
          completed: false,
          text: " 1 Thess 4:1-18",
        },
        {
          completed: false,
          text: " Ords 24:23-34",
        },
      ],
    },
    {
      completed: false,
      day: 284,
      verses: [
        {
          completed: false,
          text: "Jer 25:15-26:24",
        },
        {
          completed: false,
          text: " 1 Thess 5:1-28",
        },
        {
          completed: false,
          text: " Ps 119:17-24",
        },
      ],
    },
    {
      completed: false,
      day: 285,
      verses: [
        {
          completed: false,
          text: "Jer 27:1-29:23",
        },
        {
          completed: false,
          text: " 2 Thess 1:1-12",
        },
        {
          completed: false,
          text: " Ps 119:25-32",
        },
      ],
    },
    {
      completed: false,
      day: 286,
      verses: [
        {
          completed: false,
          text: "Jer 29:24-31:14",
        },
        {
          completed: false,
          text: " 2 Thess 2:1-17",
        },
        {
          completed: false,
          text: " Ps 119:33-40",
        },
      ],
    },
    {
      completed: false,
      day: 287,
      verses: [
        {
          completed: false,
          text: "Jer 31:15-32:25",
        },
        {
          completed: false,
          text: " 2 Thess 3:1-18",
        },
        {
          completed: false,
          text: " Ords 25:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 288,
      verses: [
        {
          completed: false,
          text: "Jer 32:26-34:22",
        },
        {
          completed: false,
          text: " 1 Tim 1:1-20",
        },
        {
          completed: false,
          text: " Ps 119:41-48",
        },
      ],
    },
    {
      completed: false,
      day: 289,
      verses: [
        {
          completed: false,
          text: "Jer 35:1-37:21",
        },
        {
          completed: false,
          text: " 1 Tim 2:1-15",
        },
        {
          completed: false,
          text: " Ps 119:49-56",
        },
      ],
    },
    {
      completed: false,
      day: 290,
      verses: [
        {
          completed: false,
          text: "Jer 38:1-40:6",
        },
        {
          completed: false,
          text: " 1 Tim 3:1-16",
        },
        {
          completed: false,
          text: " Ps 119:57-64",
        },
      ],
    },
    {
      completed: false,
      day: 291,
      verses: [
        {
          completed: false,
          text: "Jer 40:7-42:22",
        },
        {
          completed: false,
          text: " 1 Tim 4:1-16",
        },
        {
          completed: false,
          text: " Ords 25:11-20",
        },
      ],
    },
    {
      completed: false,
      day: 292,
      verses: [
        {
          completed: false,
          text: "Jer 43:1-45:5",
        },
        {
          completed: false,
          text: " 1 Tim 5:1-6:2",
        },
        {
          completed: false,
          text: " Ps 119:65-72",
        },
      ],
    },
    {
      completed: false,
      day: 293,
      verses: [
        {
          completed: false,
          text: "Jer 46:1-47:7",
        },
        {
          completed: false,
          text: " 1 Tim 6:3-21",
        },
        {
          completed: false,
          text: " Ps 119:73-80",
        },
      ],
    },
    {
      completed: false,
      day: 294,
      verses: [
        {
          completed: false,
          text: "Jer 48:1-49:6",
        },
        {
          completed: false,
          text: " 2 Tim 1:1-18",
        },
        {
          completed: false,
          text: " Ps 119:81-88",
        },
      ],
    },
    {
      completed: false,
      day: 295,
      verses: [
        {
          completed: false,
          text: "Jer 49:7-50:10",
        },
        {
          completed: false,
          text: " 2 Tim 2:1-26",
        },
        {
          completed: false,
          text: " Ords 25:21-26:2",
        },
      ],
    },
    {
      completed: false,
      day: 296,
      verses: [
        {
          completed: false,
          text: "Jer 50:11-51:14",
        },
        {
          completed: false,
          text: " 2 Tim 3:1-17",
        },
        {
          completed: false,
          text: " Ps 119:89-96",
        },
      ],
    },
    {
      completed: false,
      day: 297,
      verses: [
        {
          completed: false,
          text: "Jer 51:15-64",
        },
        {
          completed: false,
          text: " 2 Tim 4:1-22",
        },
        {
          completed: false,
          text: " Ps 119:97-104",
        },
      ],
    },
    {
      completed: false,
      day: 298,
      verses: [
        {
          completed: false,
          text: "Jer 52:1-34",
        },
        {
          completed: false,
          text: " Titus 1:1-16",
        },
        {
          completed: false,
          text: " Ps 119:105-112",
        },
      ],
    },
    {
      completed: false,
      day: 299,
      verses: [
        {
          completed: false,
          text: "Hab 1:1-3:19",
        },
        {
          completed: false,
          text: " Tit 2:1-15",
        },
        {
          completed: false,
          text: " Ords 26:3-12",
        },
      ],
    },
    {
      completed: false,
      day: 300,
      verses: [
        {
          completed: false,
          text: "Klag 1:1-2:6",
        },
        {
          completed: false,
          text: " Tit 3:1-15",
        },
        {
          completed: false,
          text: " Ps 119:113-120",
        },
      ],
    },
    {
      completed: false,
      day: 301,
      verses: [
        {
          completed: false,
          text: "Klag 2:7-3:39",
        },
        {
          completed: false,
          text: " File 1-25",
        },
        {
          completed: false,
          text: " Ps 119:121-128",
        },
      ],
    },
    {
      completed: false,
      day: 302,
      verses: [
        {
          completed: false,
          text: "Klag 3:40-5:22",
        },
        {
          completed: false,
          text: " Hebr 1:1-14",
        },
        {
          completed: false,
          text: " Ps 119:129-136",
        },
      ],
    },
    {
      completed: false,
      day: 303,
      verses: [
        {
          completed: false,
          text: "Obadja 1-21",
        },
        {
          completed: false,
          text: " Hebr 2:1-18",
        },
        {
          completed: false,
          text: " Ords 26:13-22",
        },
      ],
    },
    {
      completed: false,
      day: 304,
      verses: [
        {
          completed: false,
          text: "Joel 1:1-2:17",
        },
        {
          completed: false,
          text: " Hebr 3:1-19",
        },
        {
          completed: false,
          text: " Ps 119:137-144",
        },
      ],
    },
    {
      completed: false,
      day: 305,
      verses: [
        {
          completed: false,
          text: "Joel 2:18-3:21",
        },
        {
          completed: false,
          text: " Hebr 4:1-13",
        },
        {
          completed: false,
          text: " Ps 119:145-152",
        },
      ],
    },
    {
      completed: false,
      day: 306,
      verses: [
        {
          completed: false,
          text: "Hes1:1-3:27",
        },
        {
          completed: false,
          text: " Hebr 4:14-5:10",
        },
        {
          completed: false,
          text: " Ps 119:153-160",
        },
      ],
    },
    {
      completed: false,
      day: 307,
      verses: [
        {
          completed: false,
          text: "Hes 4:1-6:14",
        },
        {
          completed: false,
          text: " Hebr 5:11-6:12",
        },
        {
          completed: false,
          text: " Ords 26:23-27:4",
        },
      ],
    },
    {
      completed: false,
      day: 308,
      verses: [
        {
          completed: false,
          text: "Hes 7:1-9:11",
        },
        {
          completed: false,
          text: " Hebr 6:13-7:10",
        },
        {
          completed: false,
          text: " Ps 119:161-168",
        },
      ],
    },
    {
      completed: false,
      day: 309,
      verses: [
        {
          completed: false,
          text: "Hes 10:1-12:28",
        },
        {
          completed: false,
          text: " Hebr 7:11-28",
        },
        {
          completed: false,
          text: " Ps 119:169-176",
        },
      ],
    },
    {
      completed: false,
      day: 310,
      verses: [
        {
          completed: false,
          text: "Hes 13:1-15:8",
        },
        {
          completed: false,
          text: " Hebr 8:1-13",
        },
        {
          completed: false,
          text: " Ps 120:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 311,
      verses: [
        {
          completed: false,
          text: "Hes 16:1-63",
        },
        {
          completed: false,
          text: " Hebr 9:1-15",
        },
        {
          completed: false,
          text: " Ords 27:5-14",
        },
      ],
    },
    {
      completed: false,
      day: 312,
      verses: [
        {
          completed: false,
          text: "Hes 17:1-18:32",
        },
        {
          completed: false,
          text: " Hebr 9:16-28",
        },
        {
          completed: false,
          text: " Ps 121:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 313,
      verses: [
        {
          completed: false,
          text: "Hes 19:1-20:44",
        },
        {
          completed: false,
          text: " Hebr 10:1-18",
        },
        {
          completed: false,
          text: " Ps 122:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 314,
      verses: [
        {
          completed: false,
          text: "Hes 20:45-22:22",
        },
        {
          completed: false,
          text: " Hebr 10:19-39",
        },
        {
          completed: false,
          text: " Ps 123:1-4",
        },
      ],
    },
    {
      completed: false,
      day: 315,
      verses: [
        {
          completed: false,
          text: "Hes 22:23-23:49",
        },
        {
          completed: false,
          text: " Hebr 11:1-16",
        },
        {
          completed: false,
          text: " Ords 27:15-22",
        },
      ],
    },
    {
      completed: false,
      day: 316,
      verses: [
        {
          completed: false,
          text: "Hes 24:1-25:17",
        },
        {
          completed: false,
          text: " Hebr 11:17-40",
        },
        {
          completed: false,
          text: " Ps 124:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 317,
      verses: [
        {
          completed: false,
          text: "Hes 26:1-27:36",
        },
        {
          completed: false,
          text: " Hebr 12:1-13",
        },
        {
          completed: false,
          text: " Ps 125:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 318,
      verses: [
        {
          completed: false,
          text: "Hes 28:1-29:21",
        },
        {
          completed: false,
          text: " Hebr 12:14-29",
        },
        {
          completed: false,
          text: " Ps 126:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 319,
      verses: [
        {
          completed: false,
          text: "Hes 30:1-31:18",
        },
        {
          completed: false,
          text: " Hebr 13:1-25",
        },
        {
          completed: false,
          text: " Ords 27:23-28:6",
        },
      ],
    },
    {
      completed: false,
      day: 320,
      verses: [
        {
          completed: false,
          text: "Hes 32:1-33:20",
        },
        {
          completed: false,
          text: " Jak 1:1-27",
        },
        {
          completed: false,
          text: " Ps 127:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 321,
      verses: [
        {
          completed: false,
          text: "Hes 33:21-35:15",
        },
        {
          completed: false,
          text: " Jak 2:1-26",
        },
        {
          completed: false,
          text: " Ps 128:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 322,
      verses: [
        {
          completed: false,
          text: "Hes 36:1-37:28",
        },
        {
          completed: false,
          text: " Jak 3:1-18",
        },
        {
          completed: false,
          text: " Ps 129:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 323,
      verses: [
        {
          completed: false,
          text: "Hes 38:1-39:29",
        },
        {
          completed: false,
          text: " Jak 4:1-17",
        },
        {
          completed: false,
          text: " Ords 28:1-17",
        },
      ],
    },
    {
      completed: false,
      day: 324,
      verses: [
        {
          completed: false,
          text: "Hes 40:1-49",
        },
        {
          completed: false,
          text: " Jak 5:1-20",
        },
        {
          completed: false,
          text: " Ps 130:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 325,
      verses: [
        {
          completed: false,
          text: "Hes 41:1-42:20",
        },
        {
          completed: false,
          text: " 1 Pet 1:1-2:3",
        },
        {
          completed: false,
          text: " Ps 131:1-3",
        },
      ],
    },
    {
      completed: false,
      day: 326,
      verses: [
        {
          completed: false,
          text: "Hes 43:1-44:31",
        },
        {
          completed: false,
          text: " 1 Petr 2:4-25",
        },
        {
          completed: false,
          text: " Ps 132:1-18",
        },
      ],
    },
    {
      completed: false,
      day: 327,
      verses: [
        {
          completed: false,
          text: "Hes 45:1-46:24",
        },
        {
          completed: false,
          text: " 1 Petr 3:1-22",
        },
        {
          completed: false,
          text: " Ords 28:18-28",
        },
      ],
    },
    {
      completed: false,
      day: 328,
      verses: [
        {
          completed: false,
          text: "Hes 47:1-48:35",
        },
        {
          completed: false,
          text: " 1 Petr 4:1-19",
        },
        {
          completed: false,
          text: " Ps 133:1-3",
        },
      ],
    },
    {
      completed: false,
      day: 329,
      verses: [
        {
          completed: false,
          text: "Dan 1:1-2:23",
        },
        {
          completed: false,
          text: " 1 Petr 5:1-14",
        },
        {
          completed: false,
          text: " Ps 134: 1-3",
        },
      ],
    },
    {
      completed: false,
      day: 330,
      verses: [
        {
          completed: false,
          text: "Dan 2:24-3:12",
        },
        {
          completed: false,
          text: " 2 Petr 1:1-21",
        },
        {
          completed: false,
          text: " Ps 135:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 331,
      verses: [
        {
          completed: false,
          text: "Dan 3:13-4:18",
        },
        {
          completed: false,
          text: " 2 Petr 2:1-22",
        },
        {
          completed: false,
          text: " Ords 29:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 332,
      verses: [
        {
          completed: false,
          text: "Dan 4:19-5:16",
        },
        {
          completed: false,
          text: " 2 Petr 3:1-18",
        },
        {
          completed: false,
          text: " Ps 135:13-21",
        },
      ],
    },
    {
      completed: false,
      day: 333,
      verses: [
        {
          completed: false,
          text: "Dan 5:17-6:28",
        },
        {
          completed: false,
          text: " 1 Joh 1:1-2:11",
        },
        {
          completed: false,
          text: " Ps 136:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 334,
      verses: [
        {
          completed: false,
          text: "Dan 7:1-8:14",
        },
        {
          completed: false,
          text: " 1 Joh 2:12-27",
        },
        {
          completed: false,
          text: " Ps 136:13-26",
        },
      ],
    },
    {
      completed: false,
      day: 335,
      verses: [
        {
          completed: false,
          text: "Dan 8: 15-9:19",
        },
        {
          completed: false,
          text: " 1 Joh 2:28-3:10",
        },
        {
          completed: false,
          text: " Ords 29:10-18",
        },
      ],
    },
    {
      completed: false,
      day: 336,
      verses: [
        {
          completed: false,
          text: "Dan 9:20-11:1",
        },
        {
          completed: false,
          text: " 1 Joh 3:11-4:6",
        },
        {
          completed: false,
          text: " Ps 137:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 337,
      verses: [
        {
          completed: false,
          text: "Dan 11:2-35",
        },
        {
          completed: false,
          text: " 1 Joh 4:7-21",
        },
        {
          completed: false,
          text: " Ps 138:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 338,
      verses: [
        {
          completed: false,
          text: "Dan 11:36-12:13",
        },
        {
          completed: false,
          text: " 1 Joh 5:1-21",
        },
        {
          completed: false,
          text: " Ps 139:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 339,
      verses: [
        {
          completed: false,
          text: "Hag 1:1-2:23",
        },
        {
          completed: false,
          text: " 2 Joh 1-13",
        },
        {
          completed: false,
          text: " Ords 29:19-27",
        },
      ],
    },
    {
      completed: false,
      day: 340,
      verses: [
        {
          completed: false,
          text: "Sak 1:1-4:14",
        },
        {
          completed: false,
          text: " 3 Joh 1-14",
        },
        {
          completed: false,
          text: " Ps 139:11-16",
        },
      ],
    },
    {
      completed: false,
      day: 341,
      verses: [
        {
          completed: false,
          text: "Sak 5:1-8:23",
        },
        {
          completed: false,
          text: " Jud 1-25",
        },
        {
          completed: false,
          text: " Ps 139:17-24",
        },
      ],
    },
    {
      completed: false,
      day: 342,
      verses: [
        {
          completed: false,
          text: "Sak 9:1-11:17",
        },
        {
          completed: false,
          text: " Upp 1:1-20",
        },
        {
          completed: false,
          text: " Ps 140:1-5",
        },
      ],
    },
    {
      completed: false,
      day: 343,
      verses: [
        {
          completed: false,
          text: "Sak 12:1-14:21",
        },
        {
          completed: false,
          text: " Upp 2:1-17",
        },
        {
          completed: false,
          text: " Ords 30:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 344,
      verses: [
        {
          completed: false,
          text: "Ester 1:1-2:18",
        },
        {
          completed: false,
          text: " Upp 2:18-3:6",
        },
        {
          completed: false,
          text: " Ps 140:6-13",
        },
      ],
    },
    {
      completed: false,
      day: 345,
      verses: [
        {
          completed: false,
          text: "Est 2:19-5:14",
        },
        {
          completed: false,
          text: " Upp 3:7-22",
        },
        {
          completed: false,
          text: " Ps 141:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 346,
      verses: [
        {
          completed: false,
          text: "Est 6:1-8:17",
        },
        {
          completed: false,
          text: " Upp 4:1-11",
        },
        {
          completed: false,
          text: " Ps 142:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 347,
      verses: [
        {
          completed: false,
          text: "Est 9:1-10:3",
        },
        {
          completed: false,
          text: " Upp 5:1-14",
        },
        {
          completed: false,
          text: " Ords 30:11-23",
        },
      ],
    },
    {
      completed: false,
      day: 348,
      verses: [
        {
          completed: false,
          text: "Mal 1:1-2:16",
        },
        {
          completed: false,
          text: " Upp 6:1-17",
        },
        {
          completed: false,
          text: " Ps 143:1-12",
        },
      ],
    },
    {
      completed: false,
      day: 349,
      verses: [
        {
          completed: false,
          text: "Mal 2:17-4:6",
        },
        {
          completed: false,
          text: " Upp 7:1-17",
        },
        {
          completed: false,
          text: " Ps 144:1-8",
        },
      ],
    },
    {
      completed: false,
      day: 350,
      verses: [
        {
          completed: false,
          text: "Esra 1:1-2:67",
        },
        {
          completed: false,
          text: " Upp 8:1-9:12",
        },
        {
          completed: false,
          text: " Ps 144:9-15",
        },
      ],
    },
    {
      completed: false,
      day: 351,
      verses: [
        {
          completed: false,
          text: "Esra 2:68-4:5",
        },
        {
          completed: false,
          text: " Upp 9:13-10:11",
        },
        {
          completed: false,
          text: " Ords 30:24-33",
        },
      ],
    },
    {
      completed: false,
      day: 352,
      verses: [
        {
          completed: false,
          text: "Esra 4:6-5:17",
        },
        {
          completed: false,
          text: " Upp 11:1-19",
        },
        {
          completed: false,
          text: " Ps 145:1-7",
        },
      ],
    },
    {
      completed: false,
      day: 353,
      verses: [
        {
          completed: false,
          text: "Esra 6:1-7:10",
        },
        {
          completed: false,
          text: " Upp 12:1-13:1",
        },
        {
          completed: false,
          text: " Ps 145:8-13",
        },
      ],
    },
    {
      completed: false,
      day: 354,
      verses: [
        {
          completed: false,
          text: "Esra 7:11-8:14",
        },
        {
          completed: false,
          text: " Upp 13:2-18",
        },
        {
          completed: false,
          text: " Ps 145:14-21",
        },
      ],
    },
    {
      completed: false,
      day: 355,
      verses: [
        {
          completed: false,
          text: "Esra 8:15-9:15",
        },
        {
          completed: false,
          text: " Upp 14:1-13",
        },
        {
          completed: false,
          text: " Ords 31:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 356,
      verses: [
        {
          completed: false,
          text: "Esra 10:1-44",
        },
        {
          completed: false,
          text: " Upp 14:14-15:8",
        },
        {
          completed: false,
          text: " Ps 146:1-10",
        },
      ],
    },
    {
      completed: false,
      day: 357,
      verses: [
        {
          completed: false,
          text: "Neh 1:1-2:20",
        },
        {
          completed: false,
          text: " Upp 16:1-21",
        },
        {
          completed: false,
          text: " Ps 147:1-11",
        },
      ],
    },
    {
      completed: false,
      day: 358,
      verses: [
        {
          completed: false,
          text: "Neh 3:1-4:23",
        },
        {
          completed: false,
          text: " Upp 17:1-18",
        },
        {
          completed: false,
          text: " Ps 147:12-20",
        },
      ],
    },
    {
      completed: false,
      day: 359,
      verses: [
        {
          completed: false,
          text: "Neh 5:1-7:3",
        },
        {
          completed: false,
          text: " Upp 18:1-17",
        },
        {
          completed: false,
          text: " Ords 31:10-20",
        },
      ],
    },
    {
      completed: false,
      day: 360,
      verses: [
        {
          completed: false,
          text: "Neh 7:4-8:18",
        },
        {
          completed: false,
          text: " Upp 18:18-19:10",
        },
        {
          completed: false,
          text: " Ps 148:1-6",
        },
      ],
    },
    {
      completed: false,
      day: 361,
      verses: [
        {
          completed: false,
          text: "Neh 9:1-37",
        },
        {
          completed: false,
          text: " Upp 19:11-21",
        },
        {
          completed: false,
          text: " Ps 148:7-14",
        },
      ],
    },
    {
      completed: false,
      day: 362,
      verses: [
        {
          completed: false,
          text: "Neh 9:38-11:21",
        },
        {
          completed: false,
          text: " Upp 20:1-15",
        },
        {
          completed: false,
          text: " Ps 149:1-9",
        },
      ],
    },
    {
      completed: false,
      day: 363,
      verses: [
        {
          completed: false,
          text: "Neh 11:22-12:47",
        },
        {
          completed: false,
          text: " Upp 21:1-27",
        },
        {
          completed: false,
          text: " Ords 31:21-31",
        },
      ],
    },
    {
      completed: false,
      day: 364,
      verses: [
        {
          completed: false,
          text: "Neh 13:1-31",
        },
        {
          completed: false,
          text: " Upp 22:1-21",
        },
        {
          completed: false,
          text: " Ps 150:1-6",
        },
      ],
    },
  ]);
  const [startDate, setStartDate] = useLocalStorage("startDate", new Date());
  const [focus, setFocus] = useState(null);
  const [day, setDay] = useLocalStorage("day", 0);

  useEffect(() => {
    // storing input name
    localStorage.setItem("leseplan", JSON.stringify(leseplan));
  }, [leseplan]);

  useEffect(() => {
    window.location.href = `/#${day}`;
  }, [day]);

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const toggleCompleted = (day, text) => {
    const verseToChange = leseplan[day].verses.find((v) => v.text === text);

    const changedVerse = {
      ...verseToChange,
      completed: !verseToChange.completed,
    };
    const isTrue = leseplan[day].verses.map((verse) =>
      verse === verseToChange ? !verse.completed : verse.completed
    );
    setDay(leseplan.find((dag) => dag.completed === false).day + 3);
    if (!isTrue.some((element) => element === false)) {
      const changedDay = {
        ...leseplan[day],
        completed: true,
        verses: leseplan[day].verses.map((v) =>
          v.verse !== text ? v : changedVerse
        ),
      };
      console.log(day, "completed");
      setLeseplan(leseplan.map((d) => (d.day !== day ? d : changedDay)));
    } else {
      const changedDay = {
        ...leseplan[day],
        completed: false,
        verses: leseplan[day].verses.map((v) =>
          v.verse !== text ? v : changedVerse
        ),
      };
      setLeseplan(leseplan.map((d) => (d.day !== day ? d : changedDay)));
    }
  };

  return (
    <div className="h-full min-h-screen m-auto py-20 w-screen content-center justify-center flex relative bg-indigo-200 overflow-hidden">
      <div className="flex flex-col gap-10">
        <img
          src="/IMG_2738.JPG"
          alt=""
          className="w-20 h-20 rounded-full self-center mt-5 shadow-md"
        />
        <img
          src="/325608064_2207761586062061_7851081078981019259_n.jpg"
          alt=""
          className=" w-10/12 self-center rounded-md shadow-md max-w-94 "
        />
        <div>
          <button
            className="bg-violet-800 p-3 rounded-md ml-3 text-violet-50 font-medium"
            onClick={() =>
              setStartDate(
                new Date(
                  new Date().getTime() +
                    -leseplan.find((dag) => dag.completed === false).day *
                      24 *
                      60 *
                      60 *
                      1000
                )
              )
            }
          >
            {" "}
            Catch up!
          </button>
          <div className=" mx-auto grid grid-flow-col gap-2 w-80 overflow-x-auto scroll-smooth hover:scroll-auto snap-x p-4 ">
            {leseplan.map((dag) => {
              return (
                <div
                  key={dag.day}
                  id={dag.day}
                  className={
                    "w-16 h-16  rounded-md shadow-md grid gap content-center justify-center snap-start scroll-ml-2 " +
                    (focus
                      ? focus.day === dag.day
                        ? " bg-violet-300"
                        : dag.completed
                        ? " bg-violet-400 "
                        : " bg-violet-50 "
                      : dag.completed
                      ? " bg-violet-400 "
                      : " bg-violet-50 ")
                  }
                  onClick={() => setFocus(dag)}
                >
                  {" "}
                  <p className="text-xs">
                    {new Date(
                      new Date(startDate).getTime() +
                        +dag.day * 24 * 60 * 60 * 1000
                    ).toLocaleDateString("no-nb", {
                      day: "numeric",
                      month: "short",
                    })}
                  </p>
                  <p className="text-center">{dag.day + 1}</p>{" "}
                </div>
              );
            })}
          </div>
          <div className="grid content-center justify-center mt-5">
            {focus
              ? focus.verses.map((verse) => (
                  <div
                    className="flex flex-row gap-3 justify-center content-center"
                    key={verse.text}
                  >
                    {verse.completed ? (
                      <MdOutlineCheckCircle
                        onClick={() => {
                          toggleCompleted(focus.day, verse.text);
                          verse.completed = !verse.completed;
                        }}
                        className="w-7 h-7"
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        onClick={() => {
                          toggleCompleted(focus.day, verse.text);
                          verse.completed = !verse.completed;
                        }}
                        className="w-7 h-7"
                      />
                    )}

                    <p>{verse.text}</p>
                  </div>
                ))
              : ""}{" "}
          </div>
        </div>
      </div>
      <div className="absolute bottom-3 left-3">
        {" "}
        <a
          className="text-violet-800 font-serif font-medium italic"
          href="https://filipjohn.com"
        >
          {" "}
          Made by Filip John
        </a>
      </div>
      <div className="absolute top-3 left-3 text-violet-800">
        <a href="/Bibelläsningsplan.pdf" className="text-sm">
          Ladda ner PDF
        </a>
      </div>
    </div>
  );
}

export default App;
